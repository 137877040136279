<template>
    <div class="tier-container">
        <div class="pdf-container">
            <pdf :src="pdfSrc" width="100%" v-for="i in 4" :key="i" :page="i" ref="pdf" style="width: 100%;" />
        </div>
        <div class="levelTitle">Your Reduction Level</div>
        <el-table :row-class-name="tableRowClassName" :data="tierData" header-cell-class-name="adorn" size="medium"
            border ref="leftTable" class="left-table hide-table-header">
            <el-table-column label="" align="center" prop="tier_name"></el-table-column>
            <el-table-column label="" align="center" prop="tier_content" width="50px"></el-table-column>
        </el-table>
        <div class="tierTip">
            If you've received financial aid, scholarships, or subsidies that have cut your tuition fees below the
            standard amount, you have the opportunity to apply for reclassification into a lower Tuition Tier for
            greater fee reduction.
        </div>

        <div class="applyClass" style="position: relative;">
            <input class="myFileUpload" name="file" type="file" accept=".pdf" @change="getFile" />
            <span class="addInput2">
                Apply for lower tier
            </span>
        </div>
        <div style="margin-top: 20px;" v-if="file"> {{ file.name }}</div>
        <div class="tierTip1">Compile documentation of your actual tuition fee
            payment into one PDF titled with your full name.</div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 25px;">
            <el-button type="primary" size="middle" @click="submitTier()">Submit</el-button>
        </div>
    </div>
</template>
<script>
import {
    getSingaporeQiniuToken,
    getStudentReductionLevelsByProject,
    uploadStudentTuitionTier
} from '../../api/eae'
import {
    getUserId
} from '../../utils/store'
import pdf from 'vue-pdf'
import * as qiniu from 'qiniu-js'
export default ({
    name: 'index',
    components: {
        pdf
    },
    data() {
        return {
            loading: false,
            file: undefined,
            tierData: [],
            projectInfo: {},
            student_id: getUserId(),
            pass_id: this.$route.query.pass_id,
            // project_BU: this.$route.query.project_BU,
            pdfSrc: 'https://ap.learningfirst.tech/EAE%20Fee%20Reduction%20Policy.pdf',

            // PDF_LIST: [
            //     {
            //         PROJECT_BU: 'WAO',
            //         PROJECT_CODE: '',//这里面也可以添加code，
            //         LINK: 'https://ap.learningfirst.tech/WAO/Resources/WAO%20Fee%20Reduction%20Policy%20240827.pdf'
            //     }
            // ]
        }
    },
    mounted() {
        // for (let i = 0; i < this.PDF_LIST.length; i++) {
        //     if (this.project_BU == this.PDF_LIST[i].PROJECT_BU) {
        //         this.pdfSrc = this.PDF_LIST[i].LINK
        //     }
        // }
        //测试使用
        // this.project_code = '24-25_WAO_Design_Thinking'
        this.fetchData()
    },
    methods: {
        fetchData() {
            // getProjectInfoByCode(this.project_code).then((res) => {
            //     this.projectInfo = res.data
            //     this.tierData1 = this.projectInfo.level_arr
            //     let project_BU = this.projectInfo.project_BU
            //     for (let i = 0; i < this.PDF_LIST.length; i++) {
            //         if (project_BU == this.PDF_LIST[i].PROJECT_BU) {
            //             this.pdfSrc = this.PDF_LIST[i].LINK
            //         }
            //     }
            //     // console.log('this.pdfSrc :' + this.pdfSrc)
            // })
            getStudentReductionLevelsByProject(this.student_id, '').then((res) => {

                let result = res.data

                this.tierData.push({
                    'tier_name': 'Country/Region Class',
                    'tier_content': result['country_level']
                })
                this.tierData.push({
                    'tier_name': 'Current Tuition Tier',
                    'tier_content': result['finish_level']
                })
                this.tierData.push({
                    'tier_name': 'Reduction Level',
                    'tier_content': result['reduction_level']
                })
            })
        },
        async submitTier() {
            let that = this
            const result = await this.getQiniuToken()
            if (result.data.code === 0) {
                const token = result.data.data
                this.observable = qiniu.upload(
                    this.file,
                    new Date().getTime() + this.file.name,
                    token
                )
                const observer = {
                    complete: (res) => {
                        var url = 'https://ap.learningfirst.tech/' + res.key
                        that.doUploadTierInfo(url)
                    }
                }
                this.observable.subscribe(observer)
            }
        },
        doUploadTierInfo(url) {
            //https://ap.learningfirst.tech/1727592105387一网通办家长操作.pdf
            // console.log('aaaaaa :' + this.student_id)
            // console.log('project_code :' + this.project_code)
            // console.log('url :' + url)
            uploadStudentTuitionTier(this.student_id, this.pass_id, url).then((res) => {
                if (res.data.code == 0) {
                    this.$message.success('Submit Successfully')
                    this.$router.go(-1)
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 1) {
                return 'success-row';
            } else {
                return 'warning-row';
            }
        },
        getFile(e) {
            const file = e.target.files[0]
            if (file.size / 1024 / 1024 > 20) {
                this.$message({
                    message: 'The file size cannot exceed 20 MB. Please upload again.',
                    type: 'error'
                })
                this.file = null
                return false
            } else {
                this.file = file
            }
        },
        async getQiniuToken() {
            return new Promise((resolve) => {
                getSingaporeQiniuToken().then((res) => {
                    resolve(res)
                })
            })
        },
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.tier-container {
    background-color: white;
    padding: 20px;
    box-sizing: border-box;

    .pdf-container {
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
        border: 1px solid #E6D7D7;
        border-radius: 5px;
    }

    .levelTitle {
        width: calc(100% - 40px);
        margin-top: 20px;
        background-color: #5A9CF8;
        height: 30px;
        color: white;
        line-height: 30px;
        font-size: 15px;
        padding-left: 10px;
        box-sizing: border-box;
    }

    .left-table {
        margin-top: 2px;
        width: calc(100% - 40px);
        word-break: normal;
    }


    .tierTip {
        word-break: normal;
        margin-top: 20px;
        line-height: 20px;
    }

    .applyClass {
        margin-top: 30px;
        width: 160px;
        height: 36px;
        font-size: 13px;
        text-align: center;
        color: white;

        .myFileUpload {
            position: absolute;
            display: block;
            width: 100px;
            height: 40px;
            opacity: 0;
        }

        .addInput2 {
            cursor: pointer;
            font-family: "DDINAlternateRegular";
            font-weight: 600;
            color: white;
            background-color: #5A9CF8;
            height: 36px;
            width: 160px;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            display: inline-block;
        }
    }

    .tierTip1 {
        color: var(--c-primary);
        word-break: normal;
        margin-top: 20px;
        line-height: 20px;
    }
}

.el-table /deep/ .warning-row {
    background: #D0D4E8;
}

.el-table /deep/ .success-row {
    background: #E9EBF4;
}

.hide-table-header /deep/ .el-table__header-wrapper {
    display: none;
}
</style>